/* FAQ container styling */
.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 0 15vh;
  gap: 2.5rem;
  width: 100%;
  height: auto;
  z-index: 4;
  font-family: 'Plus Jakarta Sans', sans-serif;

}

.faq-title {
  font-size: 7rem;
  /* color: #ffffff; */
  margin-bottom: 4rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.faq-item {
  background: linear-gradient(179.91deg, rgba(127, 51, 185, 0.05) 9.15%, rgba(15, 16, 34, 0.05) 99.93%);
  border-radius: 1rem; /* Rounded border */
  width: 60%;
  /* max-width: 60%; */
  padding: 1.5rem;
  cursor: pointer;
  border: 0.5px solid;
  border-image-source: linear-gradient(118.48deg, rgba(33, 134, 251, 0.3) 22.69%, rgba(184, 102, 246, 0.3) 64.42%);
  border-image-slice: 1;
}

.faq-item.open{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
}

.faq-item:hover {
  background: linear-gradient(179.91deg, rgba(127, 51, 185, 0.1) 9.15%, rgba(15, 16, 34, 0.1) 99.93%);
}

.faq-question {
  font-size: 2.2rem;
  color: #BCBCBC;
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.faq-item .faq-answer {
  height: 0;
  opacity: 0;
  margin-top: 1rem;
  font-size: 1.7rem;
  color:#D7D7D7;
  font-family: 'Plus Jakarta Sans', sans-serif;
  transition: all 1s ease;
}

.faq-item.open .faq-answer {
  height: auto;
  opacity: 1;
}

.faq-icon {
  width: 1.5rem;
  height: 1.5rem;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
}

.faq-item .faq-icon.open{
  transform: rotate(180deg);
}
