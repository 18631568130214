.how_it{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    min-height: 80%;
    gap: 5%;
    position: relative;
    overflow: hidden;

}

.frame_3274{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.frame_3273{
    
    /* Frame 3273 */
    padding: 2% 4%;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;

    background: radial-gradient(57.35% 246.54% at 28.75% 0%, #1B1420 0%, #111111 94.68%);
    backdrop-filter: blur(100px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 39px;

    border: 0.5px solid;

    border-image-source: linear-gradient(103.81deg, rgba(255, 255, 255, 0) 10.76%, #694F7C 90.49%);


}

.lets_know{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    /* line-height: 18px; */
    /* identical to box height */
    text-align: center;

    background: linear-gradient(180deg, #EACEFF 58.18%, #695876 81.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heading_how_it_works{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 7rem;
    /* line-height: 88px; */
    text-align: center;

    background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.frame_3257{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    padding: 0px;
    z-index: 10;

}

.frame_3256{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 3%;
}

.card_main{
    margin: 10% 0%;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 40px;
    gap: 10px;

    background: linear-gradient(179.91deg, rgba(69, 61, 75, 0.08) 9.15%, rgba(15, 16, 34, 0.178) 99.93%);
    backdrop-filter: blur(10px);
    
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 38px;
    /* border: 1.5px solid; */


    border: 0.10px solid;
    border-image-source: linear-gradient(118.48deg, rgba(33, 134, 251, 0.3) 22.69%, rgba(184, 102, 246, 0.3) 64.42%);
    border-image-slice: 1;
    
    
    flex-grow: 1;
}

/* .card_main::before{
    content: '';
    border-radius: 38px;
    box-sizing: border-box;
    border: 0.5 solid linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%);

} */



.card_head{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    /* line-height: 25px; */
    text-align: center;

    color: #D7D7D7;
}

.card_text{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    /* line-height: 18px; */
    text-align: center;
    color: #D7D7D7;
}

.bgHowItWorks {
    /* background-image: url('../assets/how_it_works.gif'); */
    position: absolute;
    width: 60vw;
    height: 70vh;
    /* z-index: 0; */
    opacity: 0.6;
}

.blackGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background:  radial-gradient(48.23% 48.23% at 50% 45.52%, rgba(12, 12, 12, 0) 0%, #0C0C0C 83.6%);
    z-index: 1;
    mix-blend-mode: normal;
}


.pinkGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background:  linear-gradient(180deg, rgba(184, 102, 246, 0.7) 55.48%, rgba(82, 60, 99, 0.7) 100%);
    z-index: 2;
    mix-blend-mode: hue;
}

.bgHowItWorksImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.stepContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.step-indicator {
    display: flex;
    /* align-items: center; */
    gap: 2%;
}
  
.step {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    /* gap: 3%; */
    flex: 1 1;
}

.circle {
/* width: 50px; */
/* height: 50px; */
background-color: #c464f9; /* Light purple color */
color: white;
display: flex;
justify-content: center;
align-items: center;
align-self: center;
font-size: 1.5rem;
font-weight: bold;
padding: 5%;
}


.line {
    position: relative;
    left: 40%;
    top: -10%;
    width: 78%; /* Adjust the width as needed */
    height: 2px;
    margin: 0 22%; /* Spacing between steps */
    border-top: 2px dashed #DBABFF;

  }