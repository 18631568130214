.button{
    display: contents;
    filter: drop-shadow(0px 4px 40px rgba(174, 88, 239, 0.5));
}

.btn_design{
    /* position: absolute;
    width: 156px;
    height: 52px;
    left: calc(50% - 156px/2);
    top: calc(50% - 52px/2); */

    padding: 1% 3%;
    background: radial-gradient(100% 100% at 50% 0%, #D59EFF 0%, #AE58EF 50%, #9D41E2 100%);

}

.btn_text{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}