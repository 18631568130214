.about_section {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    padding-top: 10%;
    box-sizing: border-box;
    /* background: radial-gradient(ellipse at center, rgba(12, 12,12,0),  rgb(12,12,12,1)), radial-gradient(ellipse at center bottom , #C383F3, #523C63),  url("../assets/about.gif");
    background-blend-mode: hue;
    background-position: 0% 40%;
    background-size: cover;
    background-repeat: no-repeat; */
}

.about_content {
    max-width: 40%;
    padding: 2%;
    min-height: 50%;
    z-index: 5;
    height: fit-content;
}

.about_heading {
    font-family: 'Plus Jakarta Sans', sans-serif;
    background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
    font-size: 7rem;
    font-weight: 700;
    margin: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;

    /* line-height: 88px; */
    text-align: center;
}

.about_text {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.7rem;
    font-weight: 300;
    /* line-height: 1.5; */
    margin: 0;
    padding-top: 2%;
    color: #BCBCBC;
}

.pinkGradientAbout {
    position: absolute;
    width: 100%;
    height: 100%;
    background:  linear-gradient(#C383F3 0%, #523C63 100%);
    z-index: 2;
    mix-blend-mode: hue;
}

.about_background {
    position: absolute;
    width: 90vw;
    height: 90vh;
}

.about_gif {
    position: relative;
    width: 100%;
    height: 100%;
}
