

/* .navbar {
    backdrop-filter: blur(3.2px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2% 15%;
} */

/* Navigation */

/* Auto layout */

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    width: -webkit-fill-available;
    /* height: 10%; */
    padding: 2%;
    box-sizing: border-box;
    background: radial-gradient(1090.91% 1090.91% at 50% 0%, rgba(47, 41, 49, 0.2) 0%, rgba(28, 26, 29, 0.2) 42.1%, rgba(12, 12, 12, 0.2) 100%);
    backdrop-filter: blur(3.2px);
    z-index: 100;
    position: fixed;
    overflow: hidden;
    /* justify-content: space-between;
    align-items: center; */
    /* padding: 24px 200px; */
    /* gap: 235px; */
    /* isolation: isolate; */

    /* width: 1440px; */
    
    /* Note: backdrop-filter has minimal browser support */

    /* Inside auto layout */
    /* flex: none; */
    /* order: 0; */
    /* align-self: stretch; */
    /* flex-grow: 0; */
    
    /* width: 30%; */
}


/* .frame_4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    margin: 0 auto;
    z-index: 1;
    flex-grow: 1;
    order: 0;
    gap: 9%; 
} */


/* Frame 4 */

/* Auto layout */

.frame_4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4%;
    flex:9;
    margin: 0 auto;
    /* height: 10%; */

    /* Inside auto layout */
    /* flex: none;
    order: 0;
    flex-grow: 1;
    z-index: 0; */

}
/* .navbar_item {

    height: 18px;

    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #A9A9A9;

   

} */

/* Home */

.navbar_item {
    /* width: 51px; */
    /* height: 10%; */

    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 10%;
    /* identical to box height */
    text-align: center;

    color: #A9A9A9;
    

    /* Inside auto layout */
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

/* .logo {
    left: 200px;
    top: 24.09px;
} */


/* Nirmaan Logo */
.logo {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    /* align-items: center;
    position: absolute; */
    /* left: 12%;
    top: 40%; */
    /* flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1; */

}

.logo a{
    display: contents;
}

.logo img{
    width: 7rem;
}


a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Inherits the color from the parent element */
  }
  
  a:hover, a:focus, a:active {
    text-decoration: none; /* Ensures underline is removed on hover, focus, and active states */
    color: inherit; /* Inherits the color from the parent element in these states as well */
  }

