.footer-container {
  /* background-color: #121212; */
  /* color: #ffffff;
  text-align: center; */
  padding: 20px 0;
  background: url('./../assets/footer.gif');
  background-size: 100% 200%;
  width: 100vw;
  height: 40vh;
  background-position: center top;
  backdrop-filter: blur(100px);
  background-color: rgba(184, 102, 246, 0.5);
  mix-blend-mode: exclusion;
  opacity: 0.8;
}
  
.footer-menu {
  font-family: 'Plus Jakarta Sans';
  display: flex;
  justify-content: center;
  padding: 1% 0%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #BCBCBC;
}
  
.footer-menu a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 2rem;
  transition: color 0.3s ease;
}

.footer-menu a:hover {
  color: #bbbbbb;
}

.footer-social {
  display: flex;
  justify-content: center;
  padding: 1% 0%;
}

.footer-social a {
  margin: 0 10px;
  display: inline-block;
}

.footer-social img {
  width: 4rem;
  transition: transform 0.3s ease;
}

.footer-social img:hover {
  transform: scale(1.2);
}

.footer-bottom {
  font-size: 2rem;
  color: #BCBCBC;
  text-align: center;
}

.footer-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  
}
  