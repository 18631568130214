.banner_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(100% 100% at 50% 0%, #2F2931 0%, #1C1A1D 40%, #0C0C0C 100%);
    position: relative;
    overflow: hidden;
}

.gridDesign {
    background-image: url('../assets/Group\ 56.svg');
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-size: cover;
}

.bgImage {
    background: url('../assets/banner.gif') no-repeat;
    /* background-size: contain; */
    position: absolute;
    transform: rotate(90deg);
    width: 30%;
    height: auto;
    left: 35%;
    top: 10%;
    z-index: 1;
    mix-blend-mode: lighten;
    opacity: 0.05;
}


.frameTextButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.frameText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.frameSilverText{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.silverText{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 7rem;
    text-align: center;
    background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.subText {
    font-family: 'Plus Jakarta Sans';
    font-size: 2rem;    
    text-align: center;
    color: #909090;
    padding: 2% 30%;
}
