.why_crypto_main{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 100vh;
} 

.why_crypto_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url('./../assets/why_crypto_bg.jpeg'); 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.frame_3276{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    /* width: 50%;
    min-height: 30vh; */

}

.why_crypto_heading{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 7rem;
    /* line-height: 1.2; */
    text-align: center;
    background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
    color: #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.why_crypto_heading_text{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 200;
    font-size: 2rem;
    /* line-height: 1.5; */
    text-align: center;
    color: #FFFFFF;
    max-width: 60%;

}

.card_main_1{
    display: flex;
    justify-content: center;
    /* margin-top: 2%; */
    /* align-items: flex-end; */
    max-width: 60%;
    padding-top: 10%;
    /* min-height: 60vh; */
    /* background-color: #000; */

}

.card_container {
    display: flex;
    gap: 2rem; /* Gap between cards with relative units */
    /* justify-content: center; */
}


/* Card.css */
.card {
    background: linear-gradient(179.91deg, rgba(69, 61, 75, 0.02) 9.15%, rgba(15, 16, 34, 0.0445) 99.93%);
    border-radius: 2em; /* Relative unit for border radius */
    padding: 2em; /* Relative unit for padding */
    text-align: center;
    flex: 1 1;
    /* width: 90%; 
    max-width: 20rem; 
    color: white; 
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    margin: 1rem;  */
    border: 1.5px solid;

    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;


    /* border-image-slice: 1; */
}

.card_icon {
    padding: 10%;
    background: linear-gradient(179.91deg, rgba(184, 102, 246, 0.05) 9.15%, rgba(15, 16, 34, 0.05) 99.93%);
    position: relative;
    top: -3.5rem;
    display: inline-block;
    border-radius: 2rem;
    border: 0.01px solid;

    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;

    backdrop-filter: blur(100px);
}

.card_icon img {
    width: 4rem; /* Relative unit for width */
    
}

.card_title {
    margin: 0;
    font-size: 2rem; /* Font size in rem units */
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    color: #BCBCBC;
}

.card_description {
    margin: 0;
    padding: 5%;
    box-sizing: border-box;
    font-size: 1.4rem; /* Font size in rem units */
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    color: #BCBCBC;
}


/* Rectangle 86 */

/* box-sizing: border-box;

position: absolute;
width: 100px;
height: 94px;
left: 310.5px;
top: 523px; */

/* background: linear-gradient(179.91deg, rgba(184, 102, 246, 0.05) 9.15%, rgba(15, 16, 34, 0.05) 99.93%); */
/* backdrop-filter: blur(100px); */
/* Note: backdrop-filter has minimal browser support */
/* border-radius: 21px; */
