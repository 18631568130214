.research{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    align-self: center;
    width: 100vw;
    height: 100vh;
    gap: 10%
}

.researchHeading{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 7rem;
    text-align: center;

    background: linear-gradient(180deg, #FFFFFF 58.18%, #979797 81.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.researchContent{
    display: flex;
    align-items: center;
    max-width: 60%;
    min-height: 40%;
    gap: 10%;
    margin-top: 5%;
}

.researchCard{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    flex: 1 1;
    box-sizing: border-box; */
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(179.91deg, rgba(69, 61, 75, 0.08) 9.15%, rgba(15, 16, 34, 0.178) 99.93%);
    backdrop-filter: blur(10px);
    border-radius: 38px;
    padding: 2%;
    height: 100%;
    gap: 5%;
    border: 2.5px solid;
    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;
}

@media (max-width: 2600px) {
    .researchCard{
        justify-content: end;
    }

    .researchContent{
        max-width: 75%;
    }
}

.researchImage img{
    position: absolute;
    top: -15%;
    left: 10%;
    width: 80%;
    height: 50%;
    border-radius: 39px;
}


.researchCard:hover .researchImage img{
    transition: all 0.8s ease;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 39px;
}

.researchCard:hover{
    transition: all 0.8s ease;
    position: relative;
    top: -10%;
    height: 120%;
}

.researchText{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-between;
    width: 70%;
    height: 30%; */
    padding-top: 10%;
}

.researchHeadText{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 25px;
    text-align: center;

    color: #BCBCBC;
}

.researchColoredText{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 25px;
    text-align: center;

    color: #B866F6;
}

.researchSubText{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 18px;
    text-align: center;
    color: #BCBCBC;
    padding: 2% 0%;

}

.researchFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;
    /* align-items: center; */
    width: 100%;
}

.researchCollection{
    display: flex;
    align-items: center;


    /* Frame 1597878900 */

box-sizing: border-box;
padding: 5px 15px;
gap: 10px;

background: radial-gradient(57.35% 246.54% at 28.75% 0%, rgba(27, 20, 32, 0.6) 0%, rgba(17, 17, 17, 0.6) 94.68%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
backdrop-filter: blur(100px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 39px;
border: 1.5px solid;

    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;

}

.researchCollectionCircles{
    display: flex;
}

/* Ellipse 442 */
.researchCollectionCircle{
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    border-radius: 50%;
    background: linear-gradient(180deg, #B866F6 0%, #6C3C90 100%);
    border: 1px solid #100F0F;
    margin: 0px -6px;
}

.researchName{
    box-sizing: border-box;
padding: 5px 15px;
gap: 10px;

background: radial-gradient(57.35% 246.54% at 28.75% 0%, rgba(27, 20, 32, 0.6) 0%, rgba(17, 17, 17, 0.6) 94.68%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
backdrop-filter: blur(100px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 39px;

border: 1.5px solid;

    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;

}

.researchDate{
    box-sizing: border-box;
padding: 5px 15px;
gap: 10px;

background: radial-gradient(57.35% 246.54% at 28.75% 0%, rgba(27, 20, 32, 0.6) 0%, rgba(17, 17, 17, 0.6) 94.68%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
backdrop-filter: blur(100px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 39px;

border: 0.1px solid;

    border-image: linear-gradient(118.48deg, rgba(33, 135, 251, 0.107) 22.69%, rgba(184, 102, 246, 0.162) 64.42%) 1 round;

}

