@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap'); 
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    isolation: isolate;
    position: relative;
    /* background: radial-gradient(93.77% 20.88% at 50% 5%, #2F2931 0%, #1C1A1D 40%, #0C0C0C 100%); */
    background: #0C0C0C;
    color: #A9A9A9;
    font-family: 'Plus Jakarta Sans';
}

.banner_main a, .research a{
    display: contents;
}

html{
    font-size: x-small;
}

@media (min-width: 2600px) {
    html{
        font-size: medium;
    }
}

@media (min-width: 4600px) {
    html{
        font-size: x-large;
    }
}